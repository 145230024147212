// --------------------------
// Sélecteur de fichier
// --------------------------
.file-upload {
  display: grid;
  grid-template-rows: repeat(3, max-content);
  grid-template-columns: max-content minmax(0, 1fr);
  grid-template-areas:
    'label label'
    'input hint'
    'error error';
  column-gap: 48px;

  input {
    display: none;
  }
}

.file-upload__label {
  grid-area: label;
  margin-bottom: 16px;
}

.file-upload__custom-input {
  grid-area: input;
}

.file-upload__hint {
  grid-area: hint;
  color: #a6a6a6;
}

.file-upload__error {
  grid-area: error;
  color: #ed122e;
}

// --------------------------
// Date et heure
// --------------------------
.date-time {
  display: flex;

  .date-time__date {
    flex-grow: 1;
    min-width: 0;
    margin-right: 16px;
  }

  .date-time__time {
    flex-shrink: 0;
  }

  .date-time__time select {
    padding-right: 40px;
  }
}

// --------------------------
// Sélecteur de rôle
// variantes 'défaut' et 'multiple'
// --------------------------
.role-select,
.activite-select,
.note-select,
.type-select {
  display: inline-flex;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #071a39;

  label {
    display: flex;
    align-items: center;
    min-height: 2rem;
    padding: 0 16px;
    color: #071a39;
    font-size: 0.875rem;
    letter-spacing: 0.12px;
    cursor: pointer;

    &:not(:last-child) {
      border-right: 1px solid #071a39;
    }
  }

  label,
  label::after {
    transition: all ease-in-out 0.1s;
  }

  input:checked + label {
    background: #071a39;
    color: white;
  }

  // variante pour choix multiples
  &--multiple {
    flex-wrap: wrap;
    margin: -4px -8px 12px;
    border-radius: 0;
    border: none;

    label {
      margin: 4px 8px;
      border-radius: 4px;
      border: 1px solid #071a39;
    }

    input:checked + label {
      position: relative;
      background: none;
      border-color: #ffcd00;
      color: #071a39;

      &::after {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 1rem;
        width: 1rem;
        border-radius: 0.5rem;
        background: url('/assets/icons/icon-action-done.svg') no-repeat, linear-gradient(to right, #ffcd00, #ffcd00);
        background-size: 0.875rem 0.875rem, cover;
        background-position: center, center;
        content: '';
      }
    }

    input:disabled + label {
      color: #565656;
      border-color: #565656;
      background: #e9e9e9;
    }
  }

  .ds-input-group--error & {
    border-color: #ed122e;

    label {
      border-color: #ed122e;
      color: #ed122e;
    }
  }
}
