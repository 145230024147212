@import '../../assets/styles/variables';

// --------------------------
// Carte de contenu
// --------------------------
.card {
  display: grid;
  grid-template-rows: auto;
  column-gap: 24px;
  align-items: center;
  padding: 24px 16px 24px 32px;
  border-radius: 4px;
  border: 1px solid white;
  background: white;
  cursor: pointer;

  & + & {
    margin-top: 8px;
  }

  &--structure {
    grid-template-columns: repeat(2, max-content) minmax(0, 1fr) max-content;
    grid-template-areas: 'title siret . arrow';
  }

  &--contact {
    grid-template-columns: minmax(0, 2fr) repeat(2, minmax(0, 1fr)) max-content;
    grid-template-areas: 'title email telephone arrow';
  }

  &--equipe {
    grid-template-columns: minmax(0, 1fr) max-content;
    grid-template-areas: 'title arrow';
  }

  &--alert {
    grid-template-columns: minmax(0, 1fr) max-content;
    grid-template-areas: 'title arrow';
    border-color: #be0e25;
  }

  &--warning {
    border-color: #be0e25;
  }

  &--document {
    grid-template-columns: minmax(0, 1fr) max-content;
    grid-template-areas: 'title arrow';

    &.is-valid {
      border: 1px solid currentColor;
    }

    &.is-invalid {
      border: 1px solid currentColor;
      background: #fef8f8 !important;
      color: #be0e25;
    }
  }

  &--consortium-alert {
    grid-template-columns: minmax(0, 1fr) repeat(2, max-content);
    grid-template-areas: 'message button arrow';
    background: #ffcd00;
    border-color: #ffcd00;
  }

  &--pole {
    grid-template-rows: minmax(0, 1fr) min-content;
    grid-template-columns: minmax(0, 1fr) 24px repeat(2, max-content);
    grid-template-areas:
      'title . button arrow'
      'text  . button arrow';
    column-gap: 0;
    padding: 12px 24px 12px 24px;

    &:not(.is-valid) {
      min-height: 4.5rem;
    }

    &.is-valid {
      padding: 8px 24px 8px 24px;
      background: #e9e9e9;
      border-color: #e9e9e9;
      color: #737373;
      cursor: initial;
    }
  }

  &--generic-content {
    grid-template-columns: minmax(0, 1fr) max-content;
    grid-template-areas: 'content arrow';
    padding: 32px 16px 32px 32px;
  }

  &--file {
    grid-template-rows: minmax(0, 1fr) min-content;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) max-content;
    grid-template-areas:
      'title text  button'
      '.     error .';
    min-height: 3.5rem;
    padding: 7px 24px 7px 16px;
    cursor: initial;

    &.is-valid {
      border: 1px solid currentColor;
    }

    &.is-invalid {
      border: 1px solid currentColor;
      background: #fef8f8;
      color: #be0e25;
    }
  }

  &--list-file {
    grid-template-rows: minmax(0, 1fr) min-content;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) max-content;
    grid-template-areas: 'title           text button';
    min-height: 3.5rem;
    padding: 7px 24px 7px 16px;
    cursor: initial;
  }

  &--message {
    grid-template-columns: minmax(0, 1fr) max-content;
    grid-template-areas: 'title arrow';
  }

  // Carte non cliquable
  &--inactive {
    cursor: initial;
    border: none;
  }

  &--task {
    grid-template-rows: minmax(0, 1fr) min-content;
    grid-template-columns: minmax(0, 1fr) 24px repeat(2, max-content);
    grid-template-areas:
      'title . button arrow'
      'text  . button arrow';
    column-gap: 0;
    padding: 12px 24px 12px 24px;
    min-height: 4.5rem;

    &.is-valid {
      background: #e2faea;
      border-color: #008460;
      color: #008460;
      cursor: initial;
    }
  }
}

.card__title {
  grid-area: title;
  margin: 0 24px 0 0;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1rem;
  font-weight: bold;

  .card--contact &,
  .card--equipe & {
    font-weight: normal;
  }

  .card--pole & {
    font-family: 'IBM Plex Sans Condensed', sans-serif;
    font-size: 1.125rem;
  }

  .card--pole.is-valid & {
    font-size: 1rem;
  }

  .card--message & {
    font-family: 'IBM Plex Sans Condensed', sans-serif;
  }

  .card--message {
    font-style: italic;
    font-weight: normal;
    font-size: 0.8rem;
  }

  .card--file &,
  .card--list-file & {
    display: flex;
    align-items: center;
    font-size: 0.875rem;

    .icon {
      margin-right: 8px;
      font-size: 1.5rem;
    }
  }

  .card--task & {
    font-family: 'IBM Plex Sans Condensed', sans-serif;
    font-size: 1.125rem;

    @media screen and (max-width: $breakpoint-phone) {
      font-size: 1rem;
    }
  }
}

.card__text {
  grid-area: text;
  margin: 0;
  font-size: 0.875rem;
  font-style: italic;

  .card--task & {
    margin-top: 4px;
  }

  .card--pole & {
    margin-top: 4px;
  }

  .card--pole.is-valid & {
    display: none;
  }

  .card--file & {
    font-style: normal;
    line-height: 1.3;
  }

  .card--list-file & {
    font-style: normal;
    line-height: 1.3;
    text-align: right;
  }

  .card--file &--placeholder,
  .card--list-file &--placeholder {
    color: #a6a6a6;
  }

  .card--file.is-invalid & {
    color: inherit;
  }

  p {
    margin: 0;
  }
}

.card__siret {
  grid-area: siret;
}

.card__email {
  grid-area: email;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card__telephone {
  grid-area: telephone;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card__arrow,
.card__check {
  grid-area: arrow;
  font-size: 1.125rem !important;

  .card--pole & {
    color: #ffcd00;
    font-size: 1.5rem !important;
  }

  .card--pole.is-valid & {
    color: #00b785;
  }
}

.card__message {
  grid-area: message;
  font-family: 'IBM Plex Sans Condensed', sans-serif;
  font-size: 1.125rem;
  font-weight: bold;
}

.card__button {
  grid-area: button;

  .card--list-file & {
    .icon {
      font-size: 1.5rem;
    }
  }
}

.card__content-wrapper {
  grid-area: content;
}

.card__error {
  grid-area: error;
  color: #be0e25;

  .card--file &,
  .card--list-file & {
    margin-top: 8px;
  }
}

// --------------------------
// Étiquette de rôle
// --------------------------
.card__tag {
  display: inline-flex;
  align-items: center;
  height: 1.5rem;
  margin-left: 12px;
  padding: 0 8px;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: normal;

  &.is-invalid {
    border: 1px solid #be0e25;
  }

  &.is-valid {
    border: 1px solid #1b9223;
  }

  &.is-blue {
    border: 1px solid #071a39;
  }

  &.is-warning {
    border: 1px solid #997b00;
  }

  &,
  &--blue {
    background: #e3eef2;
    color: #071a39;
  }

  &--red {
    background: #fbe8df;
    color: #dc4600;
  }

  &--orange {
    background: #fff0b2;
    color: #997b00;
  }

  &--green {
    background: #e0f6f1;
    color: #00ad83;
  }
}

// --------------------------
// Boutons d'actions sur les fichiers
// --------------------------
.card__action-button {
  display: inline-flex;
  align-items: center;
  padding: 4px;
  background: none;
  border: none;
  cursor: pointer;

  .icon {
    font-size: 1rem;
  }

  &--error {
    color: #be0e25;
    cursor: default;
  }
}

.card__action-button:not(:first-child),
.card__action-button-wrapper:not(:first-child) {
  margin-left: 16px;
}

// --------------------------
// Erreur sur un fichier uploadé
// --------------------------
.card--file.is-invalid .ds-btn:not(:hover):not(:focus) {
  background: transparent;
  border-color: currentColor;
}

.file-error-message {
  display: flex;
  align-items: center;
  position: relative;
  z-index: -1;
  min-height: 2.4rem;
  margin-top: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  background: #fff;
  color: #be0e25;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
}

// --------------------------
// Tooltip sur les boutons d'action
// --------------------------
.card__action-button-wrapper {
  display: inline-block;
  position: relative;
}

.card__action-button-tooltip {
  display: none;
  position: absolute;
  width: 22.5rem;
  bottom: calc(100% + 7px);
  right: -88px;
  padding: 12px;
  border-radius: 4px;
  background: #fff;
  font-size: 1rem;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.2)) drop-shadow(0 3px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.14));

  .card__action-button-wrapper:hover &,
  .card__action-button-wrapper:focus & {
    display: block;
  }

  p {
    margin: 0 0 8px;

    &:last-child {
      margin: 0;
    }
  }

  &::after {
    position: absolute;
    top: 100%;
    right: 94px;
    height: 0;
    width: 0;
    border-top: 7px solid #fff;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: '';
  }
}

.card__arrow,
.card__check {
  grid-area: arrow;
  font-size: 1.125rem !important;

  .card--task & {
    color: #ffcd00;
    font-size: 1.5rem !important;
  }

  .card--task.is-valid & {
    color: #008460;
  }
}

.card__arrow {
  @media screen and (max-width: $breakpoint-phone) {
    display: none !important;
  }
}

.visibility_icon {
  width: 30px;
  height: 20px;
  cursor: pointer !important;
}

.clickable-icon {
  pointer-events: unset !important;
}
