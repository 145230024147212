@import 'bootstrap/scss/bootstrap';
@import 'assets/styles/ds-overrides';
@import 'assets/styles/material-overrides';
@import 'assets/styles/toastr-overrides';
@import 'assets/styles/custom-form-elements';
@import 'assets/styles/cards';

/* You can add global styles to this file, and also import other style files */
* {
  box-sizing: border-box;
}

.clickable {
  cursor: pointer;
}

.not-clickable {
  cursor: default;
}

html {
  scroll-behavior: smooth;
}

.mat-mdc-tooltip {
  white-space: pre-line;
}

body {
  margin: 0px;
  background-color: #f4f3f3;
  font-size: 0.875rem;
  line-height: 1.3;
  font-family: 'IBM Plex Sans', sans-serif;
}

a {
  outline: none;
  color: #000;
  text-decoration: none;
}

p {
  margin: 1em 0px;
}

table {
  border-collapse: inherit;
}

input[type='radio' i] {
  margin: 3px 3px 0px 5px;
}

main {
  display: block;
}

caption {
  display: none;
}

.icon {
  flex: 0 0 auto;
  display: inline-flex;
  width: 1em;
  height: 1em;
  font-size: inherit;
  pointer-events: none;
  color: inherit;
  fill: currentColor;
}

// --------------------------
// TODO: À RETRAVAILLER
// --------------------------
.mat-mdc-table * {
  box-sizing: initial;
}

.tab-container {
  overflow: auto;
  box-shadow: 0 5px 5px -3px rgba(139, 90, 90, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  margin: 0px auto;
  text-align: left;

  .mat-mdc-table {
    background-color: white;
    width: 100%;

    th {
      padding: 15px;
      font-weight: normal;
      border-bottom: 1px solid grey;

      .table-th {
        display: inline-flex;
        align-items: center;

        .filter {
          margin: auto;
        }
      }
    }

    tr {
      border-bottom: 1px solid lightgrey;
    }

    td {
      padding: 15px;
    }

    .mat-mdc-row {
      cursor: pointer;

      &:hover {
        background-color: #c7c7c7;
      }
    }
  }
}

// Header back button
.page-header__back {
  grid-area: back;
  justify-self: start;
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;

  .icon {
    margin-right: 8px;
    color: #5e514d;
    font-size: 1.25rem;
  }
}

// --------------------------
// STYLES COMMUNS
// --------------------------
.form-step {
  position: relative;
  padding: 32px;
  border-radius: 4px;
  background: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
}

.alertes-publies {
  margin: 2rem 3rem 0 3rem;
}

.alertes.no-space .alertes-publies {
  margin: 0;
}
