// --------------------------
// TOASTS
// --------------------------
.toast-container {
  top: 165px !important;
  right: -2px !important;
}

.toast-container .ngx-toastr {
  margin: 8px 0 0 !important;
  padding: 16px 24px 16px 48px !important;
  border-radius: 8px 0 0 8px !important;
  box-shadow: none !important;
  background-position: 16px calc(16px + 0.65em - 12px) !important;
  font-weight: normal !important;
  font-size: 1rem !important;
  line-height: 1.25 !important;
  width: auto !important;
  max-width: 50vw;
}

.toast-success {
  border: solid 2px #1b9223 !important;
  color: #1b9223 !important;
  background-color: #f2f9f2 !important;
  background-image: url('/assets/images/icon-action-check-circle.svg') !important;
}

.toast-error {
  border: solid 2px #b7394c !important;
  color: #be0e25 !important;
  background-color: #ffeff0 !important;
  background-image: url('/assets/images/icon-other-error.svg') !important;
}

.toast-top-right {
  right: -6px !important;
}

.toast-title {
  margin-bottom: 2px !important;
  font-weight: 500 !important;
}
.toast-info {
  border: solid 2px #0d2f66 !important;
  background-color: #e3eef2 !important;
  color: #0d2f66 !important;
  background-image: url('/assets/images/icon-action-cancel.svg') !important;
}
